import React, { useEffect, useState } from "react";
import ContactsMap from "../../../Pages/Contacts/ContactsMap/ContactsMap";
import useLanguage from "../../../../hooks/useLanguage";
import Close from "../../../../svg/close.svg";

export const Modal = ({ open, setOpen }) => {
  const langToggle = useLanguage;

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (open) {
      requestAnimationFrame(() => {
        setIsVisible(true);
      });
    } else {
      setIsVisible(false);
    }
  }, [open]);

  const closeModal = () => {
    setIsVisible(false);

    setTimeout(() => {
      setOpen(false);
    }, 300);
  };

  return (
    <aside>
      {open && (
        <div
          className={`request__button-modal
          ${isVisible ? "request__button-modal--visible" : ""}`}
        >
          <div
            className={`modal__wrapper
            ${isVisible ? "modal__wrapper--visible" : ""}
          `}
          >
            <ContactsMap langToggle={langToggle} />

            <button className="modal__btn" onClick={() => closeModal()}>
              <Close className="close" />
            </button>
          </div>
        </div>
      )}
    </aside>
  );
};
