import React, { useState } from "react";
import { Modal } from "./Modal/Modal";
import useLanguage from "../../../hooks/useLanguage";
import Arrow from '../../../svg/arrow_link.svg';

export const RequestButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  const langToggle = useLanguage;
  return (
    <>
      <button
        className="request__button"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="request__button-text">
          {langToggle(
            "Заявка",
            "Заявка",
            "Request"
          )}

          
        </span>

        <Arrow />
      </button>

      <Modal open={isOpen} setOpen={setIsOpen} />
    </>
  );
};
